import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { abortController } from 'store/utils';

import { setLocalStorage } from './utils';
import { getConfigApi } from './api';


const role = localStorage.getItem('role');
const mapType = localStorage.getItem('mapType');

export default create(devtools(
  immer((set) => abortController({
    appIsUpdated: false,
    mapType: role === 'admin' ? 'TGMap' : mapType || 'OLMap',
    config: {
      unitTypesMap() {
        return this.unitTypes.reduce((acc, item) => ({ ...acc, [Number(item.id)]: item }), {});
      },
      unitLayoutsMap() {
        return this.unitLayouts.reduce((acc, item) => ({ ...acc, [Number(item.id)]: item }), {});
      },
    },

    setAppIsUpdated: (payload, { setState }) => {
      setState({ appIsUpdated: payload });
    },

    setMapType: (payload, { setState }) => {
      setState({ mapType: payload });
      setLocalStorage('mapType', payload);
    },

    getConfig: async (_, { setState }) => {
      try {
        const { data } = await getConfigApi();

        setState((state) => {
          state.config = { ...state.config, ...data };
        });

        return data;
      } catch (error) {
        throw new Error(error.response.data);
      }
    },
  }, set, 'Common')),
  {
    name: 'Common',
  },
));
