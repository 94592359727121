import request from './utils';


export const uploadFileApi = (file, onUploadProgress) => request({
  url: '/files/upload',
  method: 'POST',
  headers: {
    'content-type': 'multipart/form-data',
  },
  data: file,
  onUploadProgress,
});

export const updateFileApi = (item) => request({
  url: `/files/${item.id}`,
  method: 'PUT',
  data: item,
});

export const attachFilesApi = (items) => request({
  url: '/files/attach',
  method: 'POST',
  data: { items },
});

export const deleteFileLinkApi = ({ pivotId }) => request({
  url: `/files/${pivotId}`,
  method: 'DELETE',
});

export const getConfigApi = () => request({
  url: '/config',
  method: 'GET',
});
