const statuses = {
  available: 'Опубликован',
  onModeration: 'На модерации',
  declined: 'Отклонён',
  soldOut: 'Продан',
  unpublished: 'Не опубликован',
};

const floorsList = {
  low: 'Низкий',
  medium: 'Средний',
  high: 'Высокий',
  null: '',
};

const paymentMethods = {
  bank_check: 'Чек',
  credit_debit_card: 'Дебитовая/кредитная карта',
  cash: 'Наличные',
  bank_transfer: 'Банковский перевод',
  crypto: 'Crypto',
};

const heightsList = {
  Simplex: 'Симплекс',
  Duplex: 'Дуплекс',
  Triplex: 'Триплекс',
  Quadruplex: 'Квадриплекс',
  'Five Floors': 'Пятиэтажный',
  'Six Floors': 'Шестиэтажный',
  simplex: 'Симплекс',
  duplex: 'Дуплекс',
  triplex: 'Триплекс',
  quadruplex: 'Квадриплекс',
  'five floors': 'Пятиэтажный',
  'six floors': 'Шестиэтажный',
};

const units = {
  units_one: '{{count}} юнит',
  units_few: '{{count}} юнитa',
  units_many: '{{count}} юнитов',
  units_other: '{{count}} юнитов',
};

module.exports = {
  translation: {
    heightsList,
    main: {
      dialogModal: {
        accept: 'Подтвердить',
        cancel: 'Отменить',
      },
      controls: {
        top: {
          emirate: 'Эмират',
          district: 'Район',
          developer: 'Застройщик',
          moreFilters: 'Больше фильтров',
          clearFilters: 'Очистить фильтры',
        },
        middle: {
          errorAddProject: 'Размещение проекта возможно только в пределах района',
        },
      },
      projectEdit: {
        create: 'Создание проекта',
        edit: 'Редактирование проекта',
        description: 'Описание',
        save: 'Сохранить',
        deleteDialog: {
          title: 'Удаление проекта',
          message: 'Реально удалить? <br/> Восстановить будет нельзя, это всё... С концами!',
        },
        closeDialog: {
          title: 'Выйти из редактирования проекта?',
          message: 'Вы не сохранили изменения в проекте. <br /> Все несохраненные данные будут потеряны.',
        },
        tabs: {
          information: 'Информация',
          typicalUnits: 'Типовые юниты',
          units: 'Юниты',
          amenities: 'Удобства',
          paymentPlans: 'План оплаты',
          documents: 'Документы',
        },
        information: {
          name: 'Название',
          namePlaceholder: 'Название',
          add: 'Добавить',
          description: 'Описание',
          developer: 'Застройщик',
          seller: 'Продавец',
          postHandover: 'Постоплата',
          handover: 'Срок сдачи',
          serviceCharge: 'Обслуживание',
          furnishing: 'Отделка',
          startOfSales: 'Старт продаж',
          eoi: 'EOI',
          noc: 'Перепродажа разрешена после',
          isLaunch: 'Лонч',
          isPublished: 'Опубликовано',
          isExclusive: 'Эксклюзив',
          save: 'Сохранить',
          architecture: 'Architecture',
          interior: 'Interior',
          amenities: 'Amenities',
          projectLocation: 'Расположение проекта (координаты)',
          projectLocationPlaceholder: 'Вставьте координаты',
          common: 'Общие',
        },
        paymentPlans: {
          plan: 'План',
          add: 'Добавить новый план',
          aboutPlan: 'О плане',
          postHandover: 'Пост оплата',
          deletePlan: 'Удалить план',
          name: 'Имя плана',
          duplicate: 'Дублировать',
          duplicateToOther: 'Дублировать в другой проект',
          description: 'Описание плана',
          stepName: 'Имя этапа оплаты',
          stepDescription: 'Описание этапа оплаты',
          save: 'Сохранить',
          ...paymentMethods,
          duplicateModal: {
            title: 'Дублирование плана оплаты',
            message: 'Выберите проект, в который хотите дублировать план оплаты',
            apply: 'Применить',
            inputPlaceholder: 'Выберите проекты',
          },
          successModal: {
            title: 'Успешно дублирован',
            text: '{{planName}} успешно скопирован в следующие проекты:',
            ok: 'Ok',
          },
          errorModal: {
            title: 'Ошибка',
            ok: 'Ok',
          },
        },
        amenities: {
          save: 'Сохранить',
        },
        documents: {
          add: 'Дабавить',
          save: 'Сохранить',
        },
        units: {
          search: 'Поиск юнитов',
          addTypical: '+ Добавить новый типовой юнит',
          download: 'Скачать юниты',
          uploadPlans: 'Загрузить планы',
          notFilled: 'Не заполнено',
          plan: 'План',
          number: 'Номер',
          type: 'Тип',
          layout: 'Планировка',
          height: 'Этажность',
          floor: 'Этаж',
          size: 'Площадь ft²',
          square: 'Площадь ft²',
          price: 'Цена AED',
          view: 'Вид',
          views: 'Виды',
          action: 'Действия',
          from: 'From',
          to: 'To',
          soldOut: 'Солдаут',
          soldOutShow: 'Показать солдаут',
          soldOutHide: 'Скрыть солдаут',
          save: 'Сохранить',
          heightsList,
          ...units,
          unitEdit: {
            selectLayout: 'Выбор планировки',
            layoutName: 'Имя планировки',
            search: 'Поиск',
            unitsList: 'Список юнитов',
            type: 'Тип',
            layout: 'Планировка',
            height: 'Этажность',
            floor: 'Этаж',
            number: 'Номер',
            square: 'Площадь',
            price: 'Цена',
            views: 'Виды',
            save: 'Сохранить',
            apply: 'Применить',
            renderersShow: 'Все юниты',
            renderersHide: 'Без планировок',
          },
        },
      },
      leftPanel: {
        districtEdit: {
          createDistrict: 'Создание района',
          editDistrict: 'Редактирование района',
          averagePrice: 'Средняя цена',
          name: 'Имя',
          description: 'Описание',
          deleteConfirmationModal: {
            title: 'Удалить район?',
            description: 'Вы уверены, что хотите удалить район?',
          },
          create: 'Создать',
          save: 'Сохранить',
        },
        districtView: {
          description: 'Описание',
          descriptionHide: 'Скрыть',
          descriptionShow: 'Показать',
          developers: 'Застройщики',
          projectsCount_one: '{{count}} проект',
          projectsCount_few: '{{count}} проекта',
          projectsCount_many: '{{count}} проектов',
          projectsCount_other: '{{count}} проектов',
          from: 'От',
        },
        projectView: {
          description: 'Описание',
          descriptionHide: 'Скрыть',
          descriptionShow: 'Показать',
          floor: 'этаж',
          resale: 'Resale',
          priceWithCommission: 'Цена с комиссией',
          priceWithoutCommission: 'Цена без комиссии',
          fullUnitsList: 'Все юниты',
          addToCatalog: 'Проект в каталог',
          tabs: {
            information: 'Информация',
            amenities: 'Удобства',
            paymentPlans: 'План оплаты',
            units: 'Юниты',
            documents: 'Документы',
            developer: 'Застройщик',
          },
          paymentMethods,
          information: {
            developer: 'Застройщик',
            district: 'Район',
            price: 'Цена',
            pricePerSquare: 'Цена за',
            size: 'Площадь',
            postHandover: 'Постоплата',
            handover: {
              title: 'Срок сдачи',
              ready: 'Сдан',
            },
            from: 'от',
            to: 'до',
            unitType: 'Тип юнита',
            serviceCharge: 'Обслуживание',
            furnishing: 'Отделка',
            startOfSales: 'Старт продаж',
            eoi: 'EOI',
            noc: 'Resale доступен после',
            managersContacts: 'Контакты застройщика',
            managersName: 'Имя',
            managersPhone: 'Телефон',
            hide: 'Скрыть',
            show: 'Показать',
            yes: 'Да',
            no: 'Нет',
          },
          units: {
            ...units,
            from: 'from',
          },
          developer: {
            resaleContactsHint: 'Контакты для ресейл юнитов указаны в его карточке.',
            comission: 'Комиссия',
            descriptionHide: 'Скрыть',
            descriptionShow: 'Показать',
          },
          floorsList,
          heightsList,
        },
        filters: {
          byEmirate: {
            title: 'Эмират',
          },
          byDistrict: {
            title: 'Район',
            search: 'Поиск',
            noData: 'Нет районов в данных эмиратах',
          },
          byDeveloper: {
            title: 'Застройщик',
            search: 'Поиск',
            noData: 'Нет компаний в данных районах',
          },
          other: {
            title: 'Больше фильтров',
            showMe: 'Показать на карте',
            developersStock: 'От застройщика',
            offPlan: 'Off plan',
            resale: 'Resale',
            soldOut: 'Sold out',
            isLaunch: 'Launches',
            price: 'Цена',
            size: 'Площадь недвижимости',
            pricePerSquare: 'Цена за {{squareMeasurement}}',
            handover: 'Срок сдачи',
            ready: 'Готовый',
            layout: 'Количество спален',
            unitType: 'Тип недвижимости',
            height: 'Этажность',
            furnishing: 'Отделка',
            paymentPlan: 'План оплаты',
            postHandover: 'Постоплата',
            serviceCharge: 'Обслуживание',
            favourites: 'Избранное',
            forAdmins: 'Для администраторов',
            allUnits: 'Все юниты',
            ourUnits: 'Наши юниты',
            unitsWithLayouts: 'Только юниты с планировками',
            unitsWithoutLayouts: 'Только юниты без планировок',
          },
        },
      },
      fullUnitsList: {
        ...units,
        floorPlan: 'План',
        number: 'Номер',
        type: 'Тип',
        layout: 'Комнаты',
        height: 'Этажность',
        floor: 'Этаж',
        size: 'Площадь',
        price: 'Стоимость',
        view: 'Вид из окна',
        action: 'Действие',
        searchPlaceholder: 'Поиск по юнитам',
        pricePerSquare: 'Цена за',
        floorsList,
        heightsList,
      },
      blockCompanyGap: {
        text_one: ' {{count}} день осталось до окончания срока текущей подписки. Для продления доступа, обратитесь к вашему менеджеру.',
        text_few: ' {{count}} дня осталось до окончания срока текущей подписки. Для продления доступа, обратитесь к вашему менеджеру.',
        text_many: ' {{count}} дней осталось до окончания срока текущей подписки. Для продления доступа, обратитесь к вашему менеджеру.',
        text_other: ' {{count}} дней осталось до окончания срока текущей подписки. Для продления доступа, обратитесь к вашему менеджеру.',
      },
    },
    personal: {
      companies: {
        all: 'Всё',
        broker: 'Брокер',
        developer: 'Застройщик',
        add: 'Добавить',
        edit: 'Изменить',
        block: 'Заблокировать',
        unblock: 'Разблокировать',
        delete: 'Удалить',
        employees_one: '{{count}} cотрудник',
        employees_few: '{{count}} cотрудникa',
        employees_many: '{{count}} cотрудников',
        employees_other: '{{count}} cотрудников',
        editCompany: {
          company: 'Компания',
          description: 'Описание',
          type: 'Тип',
          agent: 'Агент',
          developer: 'Застройщик',
          data: 'Данные',
          additionalInformation: 'Дополнительная информация',
          salesManagers: 'Менеджеры по продажам',
          addressesOfSalesOffices: 'Адреса офисов продаж',
          name: 'Название',
          managersName: 'Имя',
          managersContacts: 'Номер телефона',
          expiredAt: 'Дней до блокировки',
          blockingHint: '* Блокировка произойдёт в 00:00 по UTC указанной даты',
          companyBlocked: 'Заблокирована',
          maxUsersCount: 'Максимальное количество пользователей',
          resale: 'Resale',
          resaleLabel: 'Доступ к resale',
          maxResaleUnits: 'Количество ресейл юнитов',
          site: 'Сайт',
          address: 'Адрес',
          commission: 'Комиссия',
          commissionFrom: 'От %',
          commissionTo: 'До %',
          create: 'Создать',
          save: 'Сохранить',
        },
      },
      employees: {
        count_one: '{{count}} cотрудник',
        count_few: '{{count}} cотрудникa',
        count_many: '{{count}} cотрудников',
        count_other: '{{count}} cотрудников',
        add: 'Добавить',
        delete: 'Удалить',
        edit: 'Изменить',
        changePassword: 'Изменить пароль',
        companySelectionRequired: 'Необходимо выбрать компанию',
        editEmployee: {
          broker: 'Пользователь',
          head_of_sales_department: 'Администратор',
          admin: 'Верховный администратор',
          additionalContacts: 'Дополнительные контакты',
          user: 'Пользователь',
          name: 'Имя',
          email: 'Адрес почты',
          phone: 'Телефон',
          password: 'Пароль',
          position: 'Должность',
          create: 'Создать',
          edit: 'Изменить',
          add: 'Добавить',
          tg: 'Telegram',
          whatsapp: 'WhatsApp',
          instagram: 'Instagram',
        },
        changeEmployeePassword: {
          header: 'Изменение пароля',
          description: 'Новый пароль должен быть не менее 8 символов и включать хотя бы одну заглавную и строчную букву, цифру и символ.',
          uppercaseAndLowercaseLetterTitle: 'Пароль обязательно должен содержать минимум одну строчную и одну заглавную букву.',
          oneDigitTitle: 'Пароль обязательно должен содержать минимум одну цифру.',
          specialSymbolTitle: 'Пароль обязательно должен содержать минимум один специальный символ.',
          minimumLengthTitle: 'Длина пароля обязательно должна быть не менее 8 символов.',
          newPasswordPlaceholder: 'Новый пароль',
          confirmedNewPasswordPlaceholder: 'Подтвердите новый пароль',
          shortPasswordError: 'Пароль должен содержать не менее 8 символов.',
          submitButton: 'Изменить пароль',
        },
        roles: {
          broker: 'Пользователь',
          head_of_sales_department: 'Администратор',
          admin: 'Верховный администратор',
        },
      },
      catalog: {
        catalog: 'Каталог',
        clear: 'Очистить',
        preview: 'Предпросмотр',
        settings: 'Настройки',
        create: 'Создать каталог',
        created: 'Каталог сформирован',
        createdError: {
          title: 'Ошибка создания каталога',
          description: 'Попробуйте еще раз или повторите попытку позже',
        },
        modal: {
          title: 'Укажите параметры каталога',
          layoutImage: 'С планировками',
          layoutTable: 'Таблицей',
          darkTheme: 'Тёмная тема',
          lightTheme: 'Светлая тема',
          withMap: 'Локация проекта с описанием района',
          hideUnitsNumbers: 'Скрыть номера юнитов',
          withDeveloper: 'Описание застройщика',
          save: 'Сохранить',
          cancel: 'Отменить',
        },
        settingsModal: {
          title: 'Персонализация каталога',
          tab1: 'Общие',
          tab2: 'Дополнительные слайды',
          themeColor: 'Цвет темы',
          language: 'Язык',
          ru: 'Русский',
          en: 'Английский',
          measures: 'Единицы измерения',
          currency: 'Валюта',
          additionalCurrency: 'Дополнительная валюта',
          additionalCurrencyHint: 'Отображает цены в двух разных валютах.',
          noAdditionalCurrency: 'Без дополнительной валюты',
          unitsWithLayouts: 'Юниты с планами',
          hideUnitsNumbers: 'Скрыть номера юнитов',
          fullUnitsList: 'Полный список юнитов',
          areaSlide: 'Локация проекта',
          developerSlide: 'Описание застройщика',
          customSlide: 'Пользовательский слайд',
          customSlideTitle: 'Заголовок слайда',
          customSlideText: 'Текст слайда',
          customSlideHint: 'Отображается в конце каталога. На слайд можно добавить изображение и текст.',
          requiredField: 'Поле обязательно для заполнения',
          reset: 'Сбросить',
          save: 'Сохранить',
        },
        project: 'Проект',
        number: 'Номер',
        type: 'Тип',
        layout: 'Планировка',
        height: 'Этажность',
        floor: 'Этаж',
        size: 'Площадь',
        pricePerSquare: 'Цена за',
        price: 'Цена',
        action: 'Действия',
        heightsList,
        ...floorsList,
        yes: 'Да',
        no: 'Нет',
        noDataTitle: 'В каталоге ничего нет',
        noDataDescription: 'Добавьте юниты в каталог, выбрав их на карте',
      },
      files: {
        files: 'Файлы',
        addFolder: 'Добавить папку',
        addFile: 'Добавить файл',
      },
      menu: {
        companies: 'Компании',
        employees: 'Сотрудники',
        files: 'Файлы',
        catalog: 'Каталог',
        resale: 'Resale',
        toTheMap: 'К карте',
      },
      resale: {
        searchPlaceholder: 'Поиск по юниту',
        add: 'Добавить',
        statuses,
        status: 'Статус',
        project: 'Проект',
        number: 'Номер',
        price: 'Цена',
        square: 'Площадь',
        floor: 'Этаж',
        type: 'Тип',
        layout: 'Планировка',
        district: 'Район',
        edit: 'Редактировать',
        assignee: 'Менеджер',
        sold_out: 'Продан',
        ...units,
        floorsList,
        addUnitModal: {
          add: 'Добавить юнит',
          edit: 'Изменить юнит',
          step1: 'Шаг 1',
          step2: 'Шаг 2',
          project: 'Проект',
          projectPlaceholder: 'Выберите проект',
          projectNoOptionsText: 'Если нужный проект отсутствует в списке, свяжитесь с поддержкой через чат.',
          unitNumber: 'Номер юнита',
          unitNumberPlaceholder: 'Введите номер',
          customNameCheckbox: 'Имя не совпадает с именем пользователя',
          contactName: 'Контактное имя',
          contactNamePlaceholder: 'Введите контактное имя',
          customPhoneCheckbox: 'Телефон не совпадает с телефоном пользователя',
          contactPhone: 'Контактный телефон',
          contactPhonePlaceholder: 'Введите контактный телефон',
          documentTemplate: 'Шаблон "Listing validation form"',
          download: 'загрузить',
          square: 'Площадь в ft²',
          squarePlaceholder: '0 ft²',
          floor: 'Этаж',
          floorPlaceholder: 'Номер этажа',
          internalFloor: 'Приблизительный этаж',
          internalFloorPlaceholder: 'Выберите этаж',
          unitType: 'Тип юнита',
          unitTypePlaceholder: 'Выберите тип юнита',
          rooms: 'Количество комнат',
          roomsPlaceholder: 'Выберите количество комнат',
          height: 'Этажность',
          heightPlaceholder: 'Выберите этажность',
          price: 'Стоимость в AED',
          pricePlaceholder: '0 AED',
          priceWithCommission: 'Цена с комиссией',
          view: 'Вид',
          viewPlaceholder: 'Перечислите через запятую',
          attachDocuments: 'Прикрепите документы',
          button: {
            next: 'Продолжить',
            create: 'Создать',
            toModeration: 'На модерацию',
          },
          firstDocument: {
            title: 'Oqood/Tittle deed/SPA',
          },
          secondDocument: {
            title: 'Validation form (Contract A)',
          },
          attachLayouts: 'Прикрепите планировки',
          layouts: {
            title: 'Выберите изображение',
            formats: 'JPEG, PNG, PDF',
          },
          attachLayoutsHint: 'Необходимо загрузить планировку(-и) юнита. Загрузка рендеров проекта и иных фотографий недопустима.',
          floorsList,
          heightsList,
        },
        changeStatusUnitModal: {
          project: 'Проект',
          unitNumber: 'Номер юнита',
          square: 'Площадь',
          floor: 'Этаж',
          unitType: 'Тип юнита',
          rooms: 'Количество комнат',
          height: 'Этажность',
          price: 'Стоимость в AED',
          firstDocument: 'Oqood/Title deed',
          secondDocument: 'Validation form',
          layouts: 'Планировки',
          declineReason: 'Причина отклонения',
          deleteConfirmation: 'Уверены, что хотите удалить юнит?',
          soldOutConfirmation: 'Уверены, что хотите изменить статус юнита?',
          view: 'Вид',
          floorsList,
          heightsList,
          statuses,
          buttons: {
            decline: 'Отклонить',
            publish: 'Опубликовать',
            delete: 'Удалить',
            soldOut: 'Продан',
            unpublish: 'Снять с публикации',
          },
        },
        declineReasonModal: {
          header: 'Причина отказа',
          close: 'Закрыть',
        },
        unitsLimitReachedModal: {
          header: 'Лимит исчерпан',
          text: 'Вы достигли лимита в {{limit}} юнитов. Пожалуйста, свяжитесь с вашим администратором.',
          close: 'Закрыть',
        },
      },
    },
    login: {
      welcome: 'Добро пожаловать 👋🏽',
      description: 'Начни отслеживать лучшие предложения недвижимости в OAЭ',
      email: 'Почта',
      password: 'Пароль',
      login: 'Войти',
      forgotPassword: 'Забыли пароль?',
      registrationRequest: 'Заявка на регистрацию',
      passwordRecovery: {
        header: 'Восстановление пароля',
        body: 'Введите адрес электронной почты и мы вышлем на него ссылку для восстановления пароля.',
        forgotSubmited: 'Мы отправили вам письмо, перейдите по указанной в нем ссылке, для создания нового пароля.',
      },
      continue: 'Продолжить',
      ok: 'Ок',
    },
    resetPassword: {
      passwordReset: {
        header: 'Восстановление пароля',
        body: 'Создайте новый пароль. Он должен быть не менее 8 символов и включать хотя бы одну заглавную и строчную букву, цифру и символ.',
        uppercaseAndLowercaseLetterTitle: 'Пароль обязательно должен содержать минимум одну строчную и одну заглавную букву.',
        oneDigitTitle: 'Пароль обязательно должен содержать минимум одну цифру.',
        specialSymbolTitle: 'Пароль обязательно должен содержать минимум один специальный символ.',
        minimumLengthTitle: 'Длина пароля обязательно должна быть не менее 8 символов.',
        password: 'Новый пароль',
        passwordConfirmation: 'Подтвердите новый пароль',
      },
      passwordSubmited: {
        header: 'Теперь у вас новый пароль!',
        body: 'Новый пароль успешно создан, теперь вы можете  использовать его для входа в Geniemap.',
      },
      createPassword: 'Создать пароль',
      ok: 'Ок',
    },
    pdf: {
      create: 'Создать',
      settings: 'Настройки',
      back: 'Вернуться',
      handover: 'Срок сдачи: ',
      serviceCharge: 'Сервисный сбор: ',
      amenities: 'Удобства',
      units: 'Юниты',
      fewRenders_one: 'В проекте {{ projects }} недостаточно рендеров. Пожалуйста свяжитесь с нашим менеджером.',
      fewRenders_few: 'В проектах {{ projects }} недостаточно рендеров. Пожалуйста свяжитесь с нашим менеджером.',
      underStar: '* План оплаты относится только к оффплан юнитам',
      tableUnit: {
        number: 'Номер',
        height: 'Этажность',
        bedroom: 'Спальни',
        square: 'Площадь',
        floor: 'Этаж',
        view: 'Вид',
        price: 'Цена',
      },
      imagesUnit: {
        title: 'Характеристики юнита',
        number: 'Номер юнита:',
        price: 'Стоимость:',
        type: 'Тип юнита:',
        square: 'Общая площадь юнита:',
        floor: 'Этаж:',
        view: 'Вид:',
        finishing: 'Отделка:',
        height: 'Этажность:',
      },
    },
    common: {
      header: {
        logout: 'Выйти',
        searchPlaceholder: 'Поиск по проектам',
        units: {
          language: 'Язык',
          russianLanguage: 'Русский',
          englishLanguage: 'Английский',
          currency: 'Валюта',
          measures: 'Единица измерения',
        },
      },
      uploadArea: {
        add: 'Добавить',
        upload: 'Загрузить',
      },
      input: {
        noMatch: 'Нет совпадений',
      },
      uploadModal: {
        description: 'Описание',
        cancel: 'Отмена',
        save: 'Сохранить',
      },
      authError: 'Ошибка авторизации',
    },
    ws: {
      catalog: {
        start_catalog_generate: 'Начата генерация каталога',
        catalog_generated: 'Каталог успешно создан',
        warning: 'Предупреждение',
      },
      auth: {
        logout: 'Вы были разлогинены в связи с большим количеством подключений из под вашего аккаунта.',
      },
    },
    uploadArea: {
      invalidFileType: 'Неверный формат файла.',
      invalidFileSize: 'Файл не должен быть больше 5 МБ.',
      availableActions: 'Загрузить или вставить',
    },
  },
};
